/* eslint-env jquery */
/* global debounce */
/* eslint-disable no-mixed-spaces-and-tabs, max-len, valid-typeof */
/* eslint-disable no-undef */
require( 'slick-carousel-latest' );

( function ( $, w ) {
  let windowWidth = $( w ).width();

  const initSlick = () => {
    if ( $( '[data-slick]' ).length ) {
      const slider = $( '[data-slick]' ).not( '.bs-slider-accordion-slick' ).not( '.slick-initialized' ).slick();
      $( '[data-slick]' ).on( 'focus click touchstart', function () {
        slider.slick( 'slickPause' );
      } );
    }
  };

  const autoPlaySpeedFunction = () => {
    $( '.bs-tab-slider--progress-bar :not(.bs-slider-content)[data-slick]' ).each( ( index, ele ) => {
      if ( $( ele ).hasClass( 'slick-initialized' ) ) {
        const slick = $( ele ).slick( 'getSlick' );

        //append wrapper class and animator span to each slick slide element
        $.each( $( ele ).find( '.slick-slide' ), ( i, element ) => {
          const animatorElement = $( element ).find( '.slick-slide-wrapper .slick-slide-animator' );
          animatorElement.unwrap();
          animatorElement.remove();

          $(element).find('.slick-slide-wrapper').children().wrapAll('<div class="slick-slide-title-wrapper"></div>'); // eslint-disable-line
          const $content = $( element ).find( '.slick-slide-title-wrapper' );
          $content.append( '<span class="slick-slide-animator"></span>' );
        } );
        //set animator animation-duration
        if ( slick.options.autoplay === true ) {
          const playSpeed = slick.options.autoplaySpeed;
          $( ele )
            .find( '.slick-slide-animator' )
            .attr( 'data-speed', `${playSpeed}ms` )
            .css( 'animation-duration', `${playSpeed}ms` );
        }
      }
    } );
  };


  /* Mobile slider Progress bar*/
  const updateProgressStates = (
    sliderTotal,
    uniqSliderId,
    currentSlider
  ) => {
    if ( sliderTotal != 0 ) {
      const columnSelector = $( '#' + uniqSliderId ).parents( '.bs-column' );
      let progressBarValue = ( currentSlider / sliderTotal ) * 100 + '%';
      columnSelector.find( '.progress-bar__inner' ).css( { width: progressBarValue } );
      columnSelector.find( '.progress-bar__slide-count' ).text( currentSlider + '/' + sliderTotal );
    }
  };

  const setupSlider = ( $this ) => {
    let $slider, $sliderParent;
    if ( $this.find( '.bs-tab-slider' ).length ) {
      $slider = $this.find( '.bs-slider-content' );
      $sliderParent = $slider.parent( '.bs-tab-slider' );
    } else if ( $this.find( '.bs-slider' ).length ) {
      $slider = $this.find( '.slick-slider' );
      $sliderParent = $slider.parent( '.bs-slider' );
    }
    return { $slider, $sliderParent };
  };

  const addProgressBar = ( $sliderParent ) => {
    if ( !$sliderParent.next( '.progress-bar__container' ).length ) {
      $sliderParent.after(
        '<div class="progress-bar__container"><div class="progress-bar__wrapper"><span class="progress-bar__inner"></span></div><div class="progress-bar__slide-count"><span class="progress-bar-slide-count"></span></div></div>'
      );
    }
  };

  const getSliderSettings = ( $slider ) => {
    const slickJsonData = $slider.attr( 'data-slick' );
    let settings = { slideToShow: 1, slideToScroll: 1 };
    if ( slickJsonData ) {
      const slickDataSet = JSON.parse( slickJsonData );
      const getMobileSetting = slickDataSet.responsive[1].settings;
      settings.slideToShow = getMobileSetting.slidesToShow || settings.slideToShow;
      settings.slideToScroll = getMobileSetting.slidesToScroll || settings.slideToScroll;
    }
    return settings;
  };

  const setupSliderEvents = ( uniqueSliderElement, uniqSliderId, dataSlickLength, settings ) => {
    updateProgressStates( dataSlickLength, uniqSliderId, settings.slideToShow );

    uniqueSliderElement.on( 'init reInit afterChange', ( event, slick ) => {
      const { slideToShow, slideToScroll } = settings;
      let currentSlideNumber = calculateCurrentSlideNumber( slick, slideToShow, slideToScroll );
      updateProgressStates( slick.slideCount || 0, uniqSliderId, currentSlideNumber );
    } );

    if ( uniqueSliderElement.length !== 0 & uniqueSliderElement ) { // NOSONAR
      uniqueSliderElement.slick( 'unslick' ).slick( uniqueSliderElement.data( 'slick' ) );
    }
  };

  const calculateCurrentSlideNumber = ( slick, slideToShow, slideToScroll ) => {
    const currentItem = slick.currentSlide + 1;
    if ( slideToShow < 2 ) {
      return currentItem || slideToShow;
    }
    const totalNextItems = currentItem + slideToScroll;
    const checkCurrent = totalNextItems >= slideToShow ? currentItem + 1 : totalNextItems;
    return currentItem >= slideToShow ? checkCurrent : slideToShow;
  };

  $( '.bs-section--slider-progress-bar' ).each( ( index, element ) => {
    const $this = $( element );
    const { $slider, $sliderParent } = setupSlider( $this );

    if ( !$slider || !$sliderParent ) return;

    addProgressBar( $sliderParent );
    const settings = getSliderSettings( $slider );

    $sliderParent.each( ( index, ele ) => {
      const uniqSliderId = $( ele ).find( '.slick-slider' ).attr( 'id' );
      const dataSlickLength = $( ele ).find( '.slick-slider .slick-slide' ).length;
      const uniqueSliderElement = $( `#${uniqSliderId}` );

      setupSliderEvents( uniqueSliderElement, uniqSliderId, dataSlickLength, settings );
    } );
  } );
  /* End Mobile Progress Bar*/

  // To refresh the initiated slick sliders
  const refreshSlick = () => {
    $( '.slick-initialized' ).each( function ( key ) {
      if ( $( '.slick-initialized' )[key]?.slick ) {
        $( '.slick-initialized' )[key].slick.refresh();
      }
    } );
  };

  // To reset the autoplay enabled sliders to the initial slide when appearing on the viewport
  const resetSlidersWithIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const io = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          $.each( $( '.slick-initialized' ), ( index, element ) => {
            const slickSlider = $( element );
            const slickObject = slickSlider.slick( 'getSlick' );
            const initialSlide = slickObject.options.initialSlide;
            // Resetting only if the current slide is greater than the initial slide
            if ( slickObject.options.autoplay && slickObject.currentSlide > initialSlide ) {
              slickSlider.slick( 'slickGoTo', initialSlide );
            }
          } );
        }
      } );
    }, options );

    $.each( $( '.bs-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
    $.each( $( '.bs-tab-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
  };

  const debouncedHandlers = () => {
    const dynamicWidth = $( w ).width();
    if ( windowWidth !== dynamicWidth ) {
      initSlick();
      autoPlaySpeedFunction();
      refreshSlick();
      windowWidth = dynamicWidth;
    }
  };

  // To slide between first slide and last slide smoothly when the 'Infinite' mode is enabled
  const slider = $( '[data-slick]' );
  slider.on( 'beforeChange', ( e, slick, currentSlide, nextSlide ) => {
    if ( slick.options.infinite ) {
      const slidesCount = slick.$slides.length,
        cls = 'slick-current slick-active' + ( slick.options.centerMode ? ' slick-center' : '' );
      let selector = null;
      if ( nextSlide === 0 ) {
        selector = `.slick-slide[data-slick-index="0"], .slick-slide[data-slick-index="${slidesCount}"]`;
      } else if ( nextSlide === slidesCount - 1 ) {
        selector = `.slick-slide[data-slick-index="-1"], .slick-slide[data-slick-index="${slidesCount - 1}"]`;
      }

      if ( !selector ) return;
      // Adding a timeout since we need to add the active class after the transition has started
      setTimeout( () => {
        $( '.slick-slide', slick.$slider ).removeClass( cls );
        $( selector, slick.$slider ).addClass( cls );
      }, 10 );
    }

  } );

  // Calls when the window is fully loaded
  $( w ).on( 'load', () => {
    initSlick();
    autoPlaySpeedFunction();
    resetSlidersWithIntersectionObserver();
  } );

  // Calls on window resize
  $( w ).on( 'resize', debounce( debouncedHandlers, 500 ) );
} )( jQuery, window );